.mainresultscreen {
  min-height: 100vh !important;
}
.resultBox {
  color: white;
  margin: 40px auto;
  background: #343747;
  width: 70%;
  border-radius: 18px;
  box-shadow: 0px 1px 0px 13px rgb(72 68 68 / 10%) !important;
  position: relative;
}
.typeOfCourses {
  color: white;
  margin: 50px auto;
  background: #343747;
  width: 70%;
  border-radius: 25px;
  box-shadow: 0px 1px 0px 13px rgb(141 121 121 / 10%) !important;
  /* box-shadow: 0px 1px 0px 13px rgb(72 68 68 / 10%) !important; */
  padding: 12px 0 12px 0;
  position: relative;
}
.courseDetail {
  padding-left: 20px;
  color: white;
  margin: 20px auto !important;
  background: #242635;
  width: 95% !important;
  border-radius: 12px;
}
.courseDetail p {
  opacity: 0.7;
}
.courseDetail h2 {
  font-size: 17px;
}

.ratingGrid {
  display: flex !important;
  justify-content: space-evenly !important;
  text-align: center !important;
}
.ratingGrid div {
  height: auto !important;
  margin: auto 0px !important;
  font-size: 10px !important;
}
.ratingGrid div p {
  opacity: 0.7;
}
.languageRating {
  padding-top: 12px !important;
}
.costRating {
  padding-top: 8px !important;
}
.coursediv {
  font-weight: 900 !important;
  padding: 15px 7px 15px 7px !important;
  margin: 0 4px 0 4px !important;
}
.courseType {
  font-size: 25px !important ;
  padding: 6px 30px 6px 40px !important;
}
.percentageGrid {
  display: flex !important;
  justify-content: space-evenly !important;
  text-align: center;
  border-radius: 100px;
  padding: 5px !important;
}
.searchimg {
  padding: 5px;
  margin-top: 2px;
}
.resultBoxText {
  margin: 0 auto 30px auto;
  padding: 40px;
}
.resultBoxText1 {
  margin: 0 auto 30px auto;
  padding: 10px;
}
.resultText {
  text-align: center;
  padding: 2px;

  line-height: 1.8 !important;
}
.resultButtondiv {
  background: #46495c;
  padding: 30px;
  text-align: center;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
.resultButton {
  background: linear-gradient(
    265.05deg,
    #fece24 0%,
    #ff6d1a 50.66%,
    #ff29aa 101.84%
  );
  cursor: pointer;
  border: none;
  border-radius: 100px;
  text-align: center;
  color: white;
  padding: 15px 30px 15px 30px;
}
.resultButton:hover {
  background: linear-gradient(
    147deg,
    #f1c31c 0%,
    #f56410 50.66%,
    #ec58b1 101.84%
  );
}
.buttonDiv {
  text-align: center;
  padding: 10px;
}
.sharebutton {
  cursor: pointer;
  border: none;
  border-radius: 100px;
  background: linear-gradient(#ffffff, #bac0d6);
  padding: 15px 27px 15px 27px;
  margin: 20px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .resultBox {
    width: 80% !important;
    border-radius: 35px !important;
    margin: -18% auto 6% auto !important;
  }
  .resultButtondiv {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
  .typeOfCourses {
    width: 83% !important;
  }
  .coursediv {
    font-weight: 900 !important;
    padding: 0px 7px 0px 7px !important;
    text-align: center !important;
    margin: 0 4px 0 4px !important;
  }
  .courseType {
    text-align: left !important;
    font-size: 20px !important ;
    padding: 40px 0px 30px 17px !important;
  }
  .percentageGrid {
    height: fit-content !important;
    margin: 30px 0px 17px 0px !important;
    font-size: 12px !important;
    /* padding: 3px !important; */
  }
  .sharebutton {
    padding: 15px 20px 15px 20px;
    margin: 10px;
  }
  .showhide {
    margin: 25px 0px 0px 10px !important;
  }
  .courseDetail {
    padding-left: 5px;
    width: 92% !important;
    border-radius: 25px;
  }

  .ratingGrid {
    justify-content: space-between !important;
    padding: 3px !important;
  }
  .ratingGrid div {
    font-size: 40px !important;
  }
  .languageRating {
    padding-top: 0px !important;
  }
  .costRating {
    padding-top: 0px !important;
  }
  .selectivitydiv {
    padding-bottom: 8px !important;
  }
  .searchimg {
    padding: 0px;
    margin-top: 5px;
  }
  .resultHeading {
    font-size: 21px !important;
    line-height: 1.5 !important;
  }
  .resultText {
    font-size: 14px !important;
    line-height: 1.8 !important;
    word-break: break-all !important;
  }
  .textonmobile {
    display: none;
  }
  .resultBoxText {
    margin: 0 auto 15px auto;
    padding: 15px;
  }
  .resultButton {
    padding: 15px 50px 15px 50px;
  }
}

/* for big screens */
@media only screen and (min-device-width: 1500px) and (max-device-width: 1900px) {
  .courseType {
    padding: 6px 30px 6px 65px !important;
  }
  .showhideIcon {
    padding: 0px 0px 0px 15px !important;
  }
}
