body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.headera {
  z-index: 1;
  position: fixed;
  background: #2f323a;
  width: calc(100% - 0%);
  top: 0;
  height: 70px;
}
.options {
  text-align: center;
  color: white;
  font-family: revert;
}
.option {
  cursor: pointer;
  padding: 15px;
  margin: 0px;
  margin-bottom: 2px;
}

.left_area h3 {
  color: #fff;
  margin-bottom: -15px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
  padding: 5px;
}
.left_area span {
  color: #9198a5;
}
.logout_btn {
  padding: 10px;
  background: linear-gradient(#ffffff, #bac0d6);
  text-decoration: none;
  float: right;
  margin-top: -22px;
  margin-right: 40px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: 0.5s;
  transition-property: background;
}
.logout_btn:hover {
  background: #9198a5;
}
.sidebar {
  z-index: 1;
  top: 0;
  background: #2f323a;
  margin-top: 70px;
  padding-top: 30px;
  position: fixed;
  left: 0;
  width: 250px;
  height: calc(100% - 9%);
  transition: 0.5s;
  transition-property: left;
  overflow-y: auto;
}
.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar .profile_info .profile_image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-bottom: 10px;
}
.sidebar .profile_info h4 {
  color: #ccc;
  margin-top: 0;
  margin-bottom: 20px;
}
.sidebar a {
  color: #fff;
  display: block;
  width: 100%;
  line-height: 60px;
  text-decoration: none;
  padding-left: 40px;
  box-sizing: border-box;
  transition: 0.5s;
  transition-property: background;
  font-family: "Montserrat', sans-serif";
}
.sidebar a:hover {
  background: #9198a5;
}
.sidebar i {
  padding-right: 10px;
}
label #sidebar_btn {
  z-index: 1;
  color: #fff;
  position: fixed;
  cursor: pointer;
  left: 300px;
  font-size: 20px;
  margin: 5px 0;
  transition: 0.5s;
  transition-property: color;
}

label #sidebar_btn:hover {
  color: #19b3d3;
}
#check:checked ~ .sidebar {
  left: -185px;
}

#check:checked ~ .sidebar a span {
  display: none;
}

#check:checked ~ .sidebar a {
  font-size: 20px;
  margin-left: 165px;
  width: 100%;
}

.content {
  width: (100% - 250px);
  margin-top: 60px;
  padding: 20px;
  margin-left: 250px;
  /* background: url("/assets/img/background.png") no-repeat; */
  background: url("/src/assets/img/a2.jpeg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  transition: 0.5s;
}

#check:checked ~ .content {
  margin-left: 60px;
}

#check:checked ~ .sidebar .profile_info {
  display: none;
}

#check {
  display: none;
}

.mobile_nav {
  display: none;
}

.content .card p {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  opacity: 0.8;
}

/* Responsive CSS */

@media screen and (max-width: 780px) {
  .sidebar {
    display: none;
  }

  #sidebar_btn {
    display: none;
  }

  .content {
    margin-left: 0;
    margin-top: 0;
    padding: 10px 20px;
    transition: 0s;
  }

  #check:checked ~ .content {
    margin-left: 0;
  }

  .mobile_nav {
    display: block;
    width: calc(100% - 0%);
  }

  .nav_bar {
    background: #222;
    width: (100% - 0px);
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .nav_bar .mobile_profile_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .nav_bar .nav_btn {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s;
    transition-property: color;
  }

  .nav_bar .nav_btn:hover {
    color: #19b3d3;
  }

  .mobile_nav_items {
    background: #2f323a;
    display: none;
  }

  .mobile_nav_items a {
    color: #fff;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 60px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
  }

  .mobile_nav_items a:hover {
    background: #19b3d3;
  }

  .mobile_nav_items i {
    padding-right: 10px;
  }

  .active {
    display: block;
  }
}

table.order-table tbody {
  display: block;
  max-height: 65vh;
  overflow-y: scroll;
}

table.order-table thead,
table.order-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.order-table tbody tr:hover {
  background-color: rgb(199, 199, 199);
  cursor: pointer;
}

.edit_icon a {
  color: black !important;
}

td {
  vertical-align: middle !important;
}
