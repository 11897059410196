.mainquestionscreen {
  height: 100vh !important;
}

.questionDiv {
  width: 830px !important;
}

.questionBox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-container {
  height: 172px;
  width: 830px;
  position: relative;
}
.qboximg {
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.questionDiv {
  margin: auto !important;
  text-align: center !important;
  margin-top: 5% !important;
  height: 500px !important;
}

.questiontext {
  padding-left: 40px;
  width: 85%;
}
.answerGrid {
  padding: 1% 6% 0 12% !important;
  margin-top: 50px !important;
}

.questionNum {
  text-align: right !important;
  margin-right: 10%;
  margin-bottom: -40px;
}
.selectedOption {
  width: 50% !important;
  margin: auto !important;
  margin-bottom: 25px !important;
  border: 1px solid #6e738c !important;
  background: #7d787c !important;
  border-radius: 100px !important;
  background: linear-gradient(
    265.05deg,
    #fece24 0%,
    #ff6d1a 50.66%,
    #ff29aa 101.84%
  ) !important;
}
.selected.resultButtondiv:hover {
  background: linear-gradient(
    265.05deg,
    #fece24 0%,
    #ff6d1a 50.66%,
    #ff29aa 101.84%
  ) !important;
  cursor: pointer !important;
}
.option {
  width: 50% !important;
  margin: auto !important;
  margin-bottom: 25px !important;
  border: 1px solid #6e738c !important;
  border-radius: 100px !important;
}
.option:hover {
  background: linear-gradient(
    265.05deg,
    #fece24 0%,
    #ff6d1a 50.66%,
    #ff29aa 101.84%
  );
  cursor: pointer !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .questionBox {
    color: white;
    background: #343747;
    width: 85%;
    margin: 0 auto 30px auto;
    padding: 10px 2px 10px 20px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 1px 1px 8px 8px #2b2735;
    position: relative;
    bottom: 0%;
  }
  .qboximg {
    display: none;
  }

  .questiontext {
    padding: 10px;
    font-size: 22px;
    text-align: left;
  }

  .questionGrid {
    margin-top: -22% !important;
  }
  .answerGrid {
    padding: 1% 15% 0 15% !important;
    margin-top: 11% !important;
  }
}

/* for big screens */
/* @media only screen and (min-device-width: 1500px) and (max-device-width: 1900px) {
  .qboximg {
    width: auto;
    margin-top: auto;
  }
  .questionBox {
    color: white !important;
    position: relative !important;
    bottom: 22% !important;
    width: 64% !important;
  }
  .questiontext {
    padding-left: 60px;
  }
  .questionDiv {
    height: 650px !important;
  }
  .answerGrid {
    padding: 1% 11% 0 14% !important;
  }
} */
