.loginMain {
  text-align: center;
  border-radius: 18px;
  color: white;
  background: #343747;
  box-shadow: 0px 1px 0px 13px rgb(72 68 68 / 10%);
  padding: 20px;
  width: 50%;
  margin: 10% auto 0 auto;
}
.loginButton {
  cursor: pointer;
  font-weight: 900;
  border: none;
  border-radius: 100px;
  background: linear-gradient(#ffffff, #bac0d6);
  padding: 13px 30px;
  margin: 20px;
}
